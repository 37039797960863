<template>
  <div>

  </div>
</template>

<script>
import { getBookId } from '@/models/book'

export default {
  name: 'legacyPreview',
  created() {
    const id = this.$route.params.id
    const bookType = this.$route.query.bookType
    const sourceId = this.$route.query.author
    const bookCode = this.$route.query.bookCode
    const bookId = getBookId({id, bookType, sourceId, bookCode}, !!bookCode)
    this.$router.replace('/books/' + bookId)
  }
}
</script>

<style scoped>

</style>
